<template>
  <div>
    <notifications group="notification" position="bottom right" />
    <div class="d-flex flex-row">
      <div class="flex-row-fluid mr-lg-8">
        <div class="card card-custom card-sticky" id="kt_page_sticky_card">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Add New Product</h3>
            </div>
            <CardToolbar
              @submitEvent="onSubmit"
              back-text="Back to list"
              back-url="/products/list"
              type="Add"
              button-text="Product"
            />
          </div>
          <div class="card-body">
            <!--begin::Form-->
            <ValidationObserver v-slot="{ handleSubmit }">
              <form
                class="form"
                id="product_form"
                @submit.prevent="handleSubmit(onSubmit)"
              >
                <div class="form-group">
                  <ValidationProvider
                    name="Name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label class="font-weight-bolder" for="product_name"
                      >Name*</label
                    >
                    <input
                      v-model="product.name"
                      id="product_name"
                      type="text"
                      class="form-control form-control-solid"
                      :class="{ 'is-invalid': errors[0] }"
                      placeholder="Enter product name"
                    />
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label class="font-weight-bolder" for="product_sku"
                    >SKU</label
                  >
                  <input
                    v-model="product.sku"
                    id="product_sku"
                    class="form-control form-control-solid"
                    placeholder="Enter product SKU"
                  />
                </div>
                <div class="form-group row">
                  <div class="col-md-6">
                    <label class="font-weight-bolder" for="product_price"
                      >Price</label
                    >
                    <input
                      v-model="product.price"
                      id="product_price"
                      class="form-control form-control-solid"
                      placeholder="Enter product price"
                    />
                  </div>
                  <div class="col-md-6">
                    <label
                      class="font-weight-bolder"
                      for="product_special_price"
                      >Special Price</label
                    >
                    <input
                      v-model.number="product.specialPrice"
                      id="product_special_price"
                      class="form-control form-control-solid"
                      placeholder="Enter product special price"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label
                    class="font-weight-bolder"
                    for="product_short_description"
                    >Short Description</label
                  >
                  <textarea
                    v-model="product.shortDescription"
                    id="product_short_description"
                    class="form-control form-control-solid"
                    placeholder="Enter product short description"
                    rows="4"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label class="font-weight-bolder" for="product_description"
                    >Description</label
                  >
                  <textarea
                    v-model="product.description"
                    id="product_description"
                    class="form-control form-control-solid"
                    placeholder="Enter product description"
                    rows="8"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label class="font-weight-bolder">Attributes</label>
                  <v-select
                    class="form-control form-control--category-select"
                    placeholder="Select Attributes"
                    v-model="product.attributes"
                    :options="filteredAttributes"
                    multiple
                    :reduce="attribute => attribute['@id']"
                    label="title"
                  >
                    <template #option="{ title, parent, productCategory }">
                      <span v-if="parent"
                        ><b>-- {{ title }}</b> : {{ parent.title }} ->
                        {{ productCategory.title }}</span
                      >
                      <span v-else
                        ><b>{{ title }}</b>
                        <span
                          class="label label-lg label-light-primary label-inline"
                          >{{ productCategory.title }}</span
                        ></span
                      >
                    </template>
                  </v-select>
                </div>
                <div class="form-group mb-8">
                  <label class="font-weight-bolder">Tabs</label>

                  <div
                    v-for="(tab, index) in product.tabs"
                    :key="tab.index"
                    class="form-group row"
                  >
                    <div class="col-md-11 mb-3">
                      <div class="input-group">
                        <input
                          type="text"
                          v-model="product.tabs[index].name"
                          class="form-control form-control-solid"
                          style="font-weight: bold"
                          placeholder="Tab Name"
                        />
                      </div>
                    </div>
                    <div class="col-lg-1" style="text-align: right;">
                      <a
                        @click="removeTab(index)"
                        href="javascript:;"
                        class="btn font-weight-bold btn-danger btn-icon"
                      >
                        <i class="la la-remove"></i>
                      </a>
                    </div>
                    <div class="col-md-12">
                      <div class="input-group">
                        <textarea
                          v-model="product.tabs[index].content"
                          class="form-control form-control-solid"
                          placeholder="Tab Content"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <button
                    @click="addTab"
                    type="button"
                    class="btn btn-light-primary font-weight-bold btn-block"
                  >
                    Add Tab
                  </button>
                </div>
                <div class="separator separator-dashed my-10"></div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <label class="font-weight-bolder" for="related_products"
                      >Related Products</label
                    >
                  </div>
                  <DualListBox
                    v-if="isRelatedProductsLoaded"
                    id="related_products"
                    :source="products"
                    :destination="product.relatedProducts"
                    label="name"
                    @onChangeList="onChangeList"
                  />
                  <div v-else class="text-center" style="width: 100%;">
                    <b-spinner variant="primary" type="grow"></b-spinner>
                  </div>
                </div>
                <b-card no-body class="border-0">
                  <b-tabs pills vertical nav-wrapper-class="col-3">
                    <b-tab active>
                      <template v-slot:title>
                        <span class="nav-icon">
                          <i class="flaticon2-expand"></i>
                        </span>
                        <span class="font-weight-bolder">Multimedia</span>
                      </template>
                      <b-card-text>
                        <div class="card card-custom gutter-b">
                          <div class="card-header border-0">
                            <div class="card-title">
                              <h3 class="card-label">Multimedia</h3>
                            </div>
                          </div>
                          <div class="card-body pt-0">
                            <div class="form-group mb-2">
                              <label class="font-weight-bolder"
                                >Featured Image</label
                              >
                              <vue2Dropzone
                                class="dropzone dropzone-default dropzone-primary dz-clickable"
                                ref="myVueDropzone"
                                @vdropzone-file-added="filesAdded"
                                @vdropzone-complete="complete"
                                @vdropzone-success="success"
                                id="dropzone"
                                :options="dropzoneOptions"
                                :useCustomSlot="true"
                              >
                                <div class="dropzone-msg dz-message needsclick">
                                  <h3 class="dropzone-msg-title">
                                    Ovdje prenesite slike ili kliknite da biste
                                    je prenijeli.
                                  </h3>
                                  <span class="dropzone-msg-desc"
                                    >Prenesite do 1 slika</span
                                  >
                                </div>
                              </vue2Dropzone>
                            </div>
                            <div
                              v-if="product.featuredImage.src"
                              class="d-flex mb-8"
                            >
                              <div
                                class="symbol symbol-70 flex-shrink-0 mr-4 bg-light"
                              >
                                <div
                                  class="symbol-label"
                                  :style="{
                                    'background-image':
                                      'url(' +
                                      appPath +
                                      '/media/cache/resolve/50x50/' +
                                      product.featuredImage.src +
                                      ')'
                                  }"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <span class="nav-icon">
                          <i class="flaticon2-rocket-1"></i>
                        </span>
                        <span class="font-weight-bolder">Documents</span>
                      </template>
                      <b-card-text>
                        <vue2Dropzone
                          class="dropzone dropzone-default dropzone-primary dz-clickable"
                          ref="productDocument"
                          @vdropzone-file-added="documentAdded"
                          @vdropzone-complete="documentComplete"
                          @vdropzone-success="documentSuccess"
                          id="product_ocument"
                          :options="dropzoneDocumentsOptions"
                          :useCustomSlot="true"
                        >
                          <div class="dropzone-msg dz-message needsclick">
                            <h3 class="dropzone-msg-title">Lorem ipsum</h3>
                            <span class="dropzone-msg-desc"
                              >...lorem ipsum...</span
                            >
                          </div>
                        </vue2Dropzone>
                        <div
                          v-if="product.documents.length > 0"
                          class="row mt-5"
                        >
                          <div
                            v-for="(document, key) in product.documents"
                            :key="key"
                            class="col-md-4"
                          >
                            <div class="card card-custom gutter-b card-stretch">
                              <div class="card-body">
                                <div
                                  class="d-flex flex-column align-items-center"
                                >
                                  <inline-svg src="media/svg/files/pdf.svg" />
                                  <div class="input-group mt-5">
                                    <input
                                      type="text"
                                      v-model="product.documents[key].name"
                                      class="form-control form-control-sm form-control-solid"
                                      placeholder="Name"
                                    />
                                  </div>
                                  <div class="input-group mt-5">
                                    <button
                                      @click.prevent="removeFile(key)"
                                      class="btn btn-sm btn-block btn-danger"
                                    >
                                      Remove File
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-card-text>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <span class="nav-icon">
                          <i class="flaticon2-rocket-1"></i>
                        </span>
                        <span class="font-weight-bolder">SEO</span>
                      </template>
                      <b-card-text>
                        <Seo :meta="product.seoMeta" entity-url="/blaaa" />
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
                <button id="product_form_button" type="submit" hidden>
                  Submit
                </button>
              </form>
            </ValidationObserver>
            <!--end::Form-->
          </div>
        </div>
      </div>
      <div class="flex-column offcanvas-mobile w-300px w-xl-325px mr-lg-8">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Options</h3>
            </div>
          </div>
          <div class="card-body">
            <div class="row flex justify-content-between">
              <label class="col-6 col-form-label font-weight-bolder"
                >Publish:</label
              >
              <MCSwitch
                @checkedEvent="toggleIsPublish"
                classes="switch-icon float-right"
                name="isPublish"
                :is-checked="product.isPublish"
              />
            </div>
            <div class="row flex justify-content-between">
              <label class="col-6 col-form-label font-weight-bolder"
                >Featured:</label
              >
              <MCSwitch
                @checkedEvent="toggleIsFeatured"
                classes="switch-icon float-right"
                name="isFeatured"
                :is-checked="product.isFeatured"
              />
            </div>
            <div class="row flex justify-content-between">
              <label class="col-6 col-form-label font-weight-bolder"
                >Highlighted:</label
              >
              <MCSwitch
                @checkedEvent="toggleIsHighlighted"
                classes="switch-icon float-right"
                name="isHighlighted"
                :is-checked="product.isHighlighted"
              />
            </div>
            <div class="row flex justify-content-between">
              <label class="col-6 col-form-label font-weight-bolder"
                >In Category:</label
              >
              <MCSwitch
                @checkedEvent="toggleShowInCategory"
                classes="switch-icon float-right"
                name="showInCategory"
                :is-checked="product.showInCategory"
              />
            </div>
            <div class="row flex justify-content-between">
              <label class="col-6 col-form-label font-weight-bolder"
                >Is B2C:</label
              >
              <MCSwitch
                @checkedEvent="toggleIsB2C"
                classes="switch-icon float-right"
                name="isB2C"
                :is-checked="product.isB2C"
              />
            </div>
            <div class="row flex justify-content-between">
              <label class="col-8 col-form-label font-weight-bolder"
                >Quantity:</label
              >
              <div class="col-4 mt-1 pr-0">
                <input
                  type="number"
                  v-model.number="product.stockQuantity"
                  class="form-control form-control-sm form-control-solid"
                />
              </div>
            </div>
            <div class="row flex justify-content-between">
              <label class="col-8 col-form-label font-weight-bolder"
                >Max Discount:</label
              >
              <div class="col-4 mt-1 pr-0">
                <input
                  type="number"
                  v-model.number="product.maxDiscount"
                  class="form-control form-control-sm form-control-solid"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card card-custom">
          <div class="card-header border-0">
            <div class="card-title">
              <h3 class="card-label">Taxonomies</h3>
            </div>
          </div>
          <div class="card-body py-0">
            <div class="form-group">
              <label class="font-weight-bolder">Category</label>
              <v-select
                class="form-control form-control--category-select"
                placeholder="Select category"
                v-model="product.category"
                :options="filteredCategories"
                :reduce="category => category['@id']"
                label="title"
              >
                <template #option="{ title, parent }">
                  <span v-if="parent">-- {{ title }}</span>
                  <span v-else>{{ title }}</span>
                </template>
              </v-select>
              <button
                @click="toggleCategoryModal"
                class="btn btn-light-primary btn-block mt-2"
              >
                Add New
              </button>
            </div>
            <div class="form-group">
              <label class="font-weight-bolder">Brand</label>
              <v-select
                class="form-control form-control--category-select"
                placeholder="Select brand"
                v-model="product.brand"
                :options="brands"
                :reduce="brand => brand['@id']"
                label="name"
              />
            </div>
            <div class="form-group">
              <label class="font-weight-bolder">Tags</label>
              <v-select
                class="form-control form-control--category-select"
                placeholder="Select Tags"
                v-model="product.tags"
                :options="tags"
                multiple
                :reduce="tag => tag['@id']"
                label="title"
              >
                <template #option="{ title }">
                  <span>{{ title }}</span>
                </template>
              </v-select>
              <button
                @click="toggleTagModal"
                class="btn btn-light-primary btn-block mt-2"
              >
                Add New
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-modal id="category_modal" centered hide-header hide-footer>
          <form>
            <div class="form-group">
              <label>Parent</label>
              <v-select
                class="form-control form-control--category-select"
                placeholder="Select parent category"
                v-model="category.parent"
                :options="parentCategories"
                :reduce="category => category['@id']"
                label="title"
              >
                <template #option="{ title, parent }">
                  <span v-if="parent">-- {{ title }}</span>
                  <span v-else>{{ title }}</span>
                </template>
              </v-select>
            </div>
            <div class="form-group">
              <label for="postTitle">Title</label>
              <input
                v-model="category.title"
                id="categoryTitle"
                class="form-control form-control-solid"
                placeholder="Enter Category title"
              />
            </div>

            <div class="form-group">
              <label for="postTitle">Subtitle</label>
              <input
                v-model="category.subtitle"
                id="categorySubtitle"
                class="form-control form-control-solid"
                placeholder="Enter Category subtitle"
              />
            </div>

            <div class="form-group">
              <label for="postContent">Description</label>
              <textarea
                v-model="category.description"
                id="categoryDescription"
                class="form-control form-control-solid"
                rows="8"
              ></textarea>
            </div>

            <div class="form-group mb-0">
              <button
                @click.prevent="addCategory"
                class="btn btn-light-primary btn-block"
              >
                Add Category
              </button>
            </div>
          </form>
        </b-modal>
      </div>
      <div class="col-md-12">
        <b-modal id="tags_modal" centered hide-header hide-footer>
          <form>
            <div class="form-group">
              <label for="tag_title">Title</label>
              <input
                v-model="tag.title"
                id="tag_title"
                class="form-control form-control-solid"
                placeholder="Enter Tag title"
              />
            </div>
            <div class="form-group mb-0">
              <button
                @click.prevent="addTag"
                class="btn btn-light-primary btn-block"
              >
                Add Tag
              </button>
            </div>
          </form>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import Seo from '@/view/content/components/seo/Seo'
import ApiService from '@/core/services/api.service'
import MCSwitch from '@/view/content/components/switch/MCSwitch'
import vSelect from 'vue-select'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import vue2Dropzone from 'vue2-dropzone'
import DualListBox from '@/view/content/components/dual-list-box/DualListBox'

extend('required', {
  ...required,
  message: 'This field is required'
})

export default {
  name: 'NewProduct',
  components: {
    CardToolbar,
    Seo,
    MCSwitch,
    vSelect,
    vue2Dropzone,
    DualListBox
  },
  data() {
    return {
      product: {
        name: null,
        shortDescription: null,
        description: null,
        price: null,
        specialPrice: null,
        maxDiscount: null,
        sku: null,
        stockQuantity: null,
        featuredImage: {
          src: '',
          alt: ''
        },
        brand: null,
        category: null,
        tags: [],
        attributes: [],
        tabs: [],
        documents: [],
        relatedProducts: [],
        seoMeta: {
          ogTitle: null,
          description: null,
          keywords: null
        },
        isPublish: true,
        isFeatured: false,
        isHighlighted: false,
        showInCategory: false,
        isB2C: false
      },
      parentCategories: [],
      childrenCategories: [],
      filteredCategories: [],
      attributes: [],
      parentAttributes: [],
      childrenAttributes: [],
      filteredAttributes: [],
      brands: [],
      tags: [],
      products: [],
      isRelatedProductsLoaded: false,
      selectedProducts: [],
      category: {
        title: null,
        subtitle: null,
        description: null,
        parent: null
      },
      tag: {
        title: null
      },
      dropzoneOptions: {
        url: process.env.VUE_APP_API_ENDPOINT + '/upload',
        thumbnailWidth: 150,
        maxFilesize: 1
      },
      dropzoneDocumentsOptions: {
        url: process.env.VUE_APP_API_ENDPOINT + '/upload',
        thumbnailWidth: 150,
        maxFilesize: 10
      },
      appPath: process.env.VUE_APP_ROUTE_API_URL
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Products', route: '/products/list' },
      { title: 'Add New' }
    ])

    this.getBrands('/brands', '?page=1').then(({ data }) => {
      this.brands = [...data['hydra:member']]
    })
    this.getCategories(
      '/product_categories',
      '?order[createdAt]=desc&itemsPerPage=90'
    ).then(({ data }) => {
      this.categories = [...data['hydra:member']]

      this.parentCategories = this.categories.filter(
        category => category.parent === undefined
      )
      this.childrenCategories = this.categories.filter(
        category => category.parent !== undefined
      )

      this.parentCategories.forEach(parentCategory => {
        this.filteredCategories.push(parentCategory)
        this.childrenCategories.forEach(childrenCategory => {
          if (childrenCategory.root['@id'] === parentCategory.root) {
            this.filteredCategories.push(childrenCategory)
          }
        })
      })
    })
    this.getTags('/tags', '?page=1').then(({ data }) => {
      this.tags = [...data['hydra:member']]
    })
    this.getAttributes('/attributes', '?itemsPerPage=300').then(({ data }) => {
      this.attributes = [...data['hydra:member']]

      this.parentAttributes = this.attributes.filter(
        attribute => attribute.parent === undefined
      )
      this.childrenAttributes = this.attributes.filter(
        attribute => attribute.parent !== undefined
      )

      this.parentAttributes.forEach(parentAttribute => {
        this.filteredAttributes.push(parentAttribute)
        this.childrenAttributes.forEach(childrenAttribute => {
          if (childrenAttribute.root['@id'] === parentAttribute.root) {
            this.filteredAttributes.push(childrenAttribute)
          }
        })
      })
    })
    this.getProducts(
      '/products',
      '?properties[]=id&properties[]=featuredImage&properties[]=name&properties[]=slug&properties[]=price&properties[]=specialPrice&properties[]=sku&properties[]=category&properties[]=attributes&itemsPerPage=2000'
    ).then(({ data }) => {
      this.products = [...data['hydra:member']]
      this.isRelatedProductsLoaded = true
    })
  },
  methods: {
    async onSubmit() {
      // document.getElementById('product_form_button').click()
      if (!this.product.specialPrice) delete this.product.specialPrice

      this.postProduct(this.product)
        .then(({ status }) => {
          if (status === 201) {
            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'Product successful add in database'
            })
            setTimeout(() => {
              this.$router.push({ name: 'products-list' })
            }, 500)
          }
        })
        .catch(() => {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Product submit error'
          })
        })
    },
    async postProduct(entity) {
      try {
        return await ApiService.post('/products', entity)
      } catch (e) {
        throw new Error(e)
      }
    },
    async getBrands(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    async getCategories(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    async getTags(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    async getAttributes(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    async getProducts(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    toggleIsPublish() {
      this.product.isPublish = !this.product.isPublish
    },
    toggleIsFeatured() {
      this.product.isFeatured = !this.product.isFeatured
    },
    toggleIsHighlighted() {
      this.product.isHighlighted = !this.product.isHighlighted
    },
    toggleShowInCategory() {
      this.product.showInCategory = !this.product.showInCategory
    },
    toggleIsB2C() {
      this.product.isB2C = !this.product.isB2C
    },
    addTab() {
      this.product.tabs.push({
        name: '',
        content: ''
      })
    },
    removeTab(index) {
      const confirmation = confirm('Do you want to delete this Tab?')
      if (confirmation === false) return

      this.product.tabs.splice(index, 1)
    },
    toggleCategoryModal() {
      this.$root.$emit('bv::show::modal', 'category_modal')
    },
    addCategory() {
      this.postCategory(this.category)
        .then(({ data }) => {
          this.categories.push(data)
          this.$root.$emit('bv::hide::modal', 'category_modal')
        })
        .catch(() => {})
    },
    async postCategory(entity) {
      try {
        return await ApiService.post('/product_categories', entity)
      } catch (e) {
        throw new Error(e)
      }
    },
    toggleTagModal() {
      this.$root.$emit('bv::show::modal', 'tags_modal')
    },
    addTag() {
      this.postTag(this.tag)
        .then(({ data }) => {
          this.tags.push(data)
          this.$root.$emit('bv::hide::modal', 'tags_modal')
        })
        .catch(() => {})
    },
    async postTag(entity) {
      try {
        return await ApiService.post('/tags', entity)
      } catch (e) {
        throw new Error(e)
      }
    },
    onChangeList({ source, destination }) {
      this.products = source
      this.product.relatedProducts = destination
    },
    filesAdded() {},
    success(_, response) {
      this.product.featuredImage.src = '/uploads/' + response
    },
    complete() {},

    documentAdded() {},
    documentComplete() {},
    documentSuccess(_, response) {
      this.product.documents.push({
        name: '',
        src: '/uploads/' + response
      })
    },
    removeFile(key) {
      const confirmation = confirm('Do you want to delete this document?')
      if (confirmation === false) return

      this.product.documents.splice(key, 1)
    }
  }
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control--category-select,
.form-control--tags-select {
  height: unset !important;

  .vs__dropdown-toggle {
    padding: 0;
    border: 0;
    border-radius: 0;
  }
  .vs__dropdown-menu {
    border: 1px solid #e4e6ef;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    li {
      padding: 0.65rem 1rem;

      &:hover {
        background: #f3f6f9;
        color: #3f4254;
      }
    }
  }

  .vs__open-indicator {
  }
  .vs__dropdown-toggle {
  }
  .vs__clear {
    display: none;
  }
  .vs__selected {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.25rem 0.75rem;
    font-size: 1rem;
    margin: 0;
    position: relative;
    float: left /*rtl:right*/;
    color: #3f4254;
    background: #f3f6f9;
    border: 0;
    border-radius: 0.42rem;
  }
  .vs__no-options {
  }
  .vs__spinner {
  }
  .vs__deselect {
  }
  .vs__active {
  }
  .vs__dropdown-option--highlight {
    background: #ebedf3;
    color: #3f4254;
  }
}
</style>
